<template>
    <div class="card card-row mt-3" style="background-color: #ebecf0; color: #172b4d; width:280px;">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="editMode == false" @dblclick="enableEdit">{{ listing.name }}</span>
                
                <div v-if="editMode == true" class="input-group">
                    <input type="text" class="form-control form-control-sm" v-model="title" @keyup.enter="saveTitle"/>
                    <div class="input-group-append">
                        <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('billing', index)"><i class="fas fa-save"></i></button> -->
                        <button class="btn btn-sm btn-secondary" type="button" @click="disableEdit"><i class="fas fa-times"></i></button>
                    </div>
                </div>
            </h3>
            <div class="card-tools">
                <!-- <button type="button" class="btn btn-tool"><i class="fas fa-ellipsis-v"></i></button> -->
                <nav class="py-0 navbar navbar-expand justify-content-end">
                    <ul class="navbar-nav">  
                        <li class="nav-item d-none d-md-block">
                            <div class="mr-1 btn-group" role="group">
                                <button :id="'btnBoardList' + listing.id" type="button" class="btn btn-tool" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </button>
                                <div class="dropdown-menu" :aria-labelledby="'btnBoardList' + listing.id"> 
                                    <a class="dropdown-item" href="#" @click="showAddForm">Karte hinzufügen</a>
                                    <div class="dropdown-divider"></div>       
                                    <a class="dropdown-item text-danger" href="#" @click="deleteList(listing.id)">Löschen</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="card-body">
            <draggable v-model="listing.cards" group="cards" tag="div" animation="200" @change="cardMoved" drag-class="drag" ghost-class="ghost">
                    <ListingItem v-for="card in listing.cards" :key="card.id" :card="card" :list="listing" :users="users" :tags="tags" v-on:card_deleted="deleteCard"/>
            </draggable>
            <template v-if="newItem == true">
                <div class="row">
                    <div class="col-md-12">
                        <textarea class="form-control form-control-sm list-item-edit" dir="auto" data-autosize="true" style="overflow:hidden; overflow-wrap: break-word; resize: none; height:90px;" v-model="form.title" ></textarea>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-secondary btn-sm mt-2 form-control" @click="addCard">Speichern</button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-danger btn-sm mt-2 form-control" @click="editItemStop">Abbrechen</button>
                    </div>
                </div>
            </template>
            <button v-else class="btn btn-outline-secondary" @click="showAddForm"><i class="fas fa-plus"></i> Karte hinzufügen</button>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import ListingItem from './ListingItem';
export default {

    props:[
        'list',
        'users',
        'tags',
    ],

    components: {
        draggable,
        ListingItem
    },

    computed: {
        listing() {
            return this.list;
        }
    },

    data(){
        return {
            form: new window.Form({
                title: ''
            }),
            newItem: false,
            editMode: false,
            title: '',
        }
    },

    methods:{

        saveTitle(){
            this.axios
                .put('/listings/' +  this.listing.id, {
                    name: this.title
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    // this.card.title = this.form.title;
                    this.listing.name = response.data.data.name;
                    this.editMode = false;
                    this.title = '';
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        enableEdit(){
            this.title = this.listing.name;
            this.editMode = true;
        },

        disableEdit(){
            this.editMode = false;
            this.title = '';
        },

        deleteCard(id){

            const card = this.listing.cards.findIndex(item => item.id == id); 
            this.$delete(this.listing.cards, card); 

        }, 

        editItemStop(){
            this.newItem = false;
            this.form.clear();
            this.form.reset();
        },

        showAddForm(){
            this.newItem = true;
        },

        addCard(){
            this.axios
                .post('/cards', {
                    'title': this.form.title,
                    'listing_id': this.listing.id
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    // this.card.title = this.form.title;
                    this.listing.cards.push(response.data.data);
                    this.form.clear();
                    this.form.reset();
                    this.newItem = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        deleteList(id){
            this.$swal({
                    title: "Möchtest du die Liste wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/listings/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Liste gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                this.$parent.$parent.loadBoard();
                            })
                            .catch((error) => {
                                var message = 'Da ist etwas schief gelaufen'; 
                                if('error' in error.response.data)
                                {
                                    message = error.response.data.error; 
                                }
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: message, 
                                });
                            });
                    }
                });
        },

        cardMoved(e){
            let item = e.added || e.moved;

            if(!item) return;

            let index = item.newIndex;
            let prevCard = this.listing.cards[index - 1];
            let nextCard = this.listing.cards[index + 1];
            let card = this.listing.cards[index];
            
            let position = card.position;

            // console.log(prevCard);
            // console.log(nextCard);
            
            if (prevCard && nextCard) {
                position = (prevCard.position + nextCard.position) / 2;
            } else if (prevCard) {
                position = prevCard.position + (prevCard.position / 2);
            } else if (nextCard) {
                position = nextCard.position / 2;
            }

            // console.log(position);

            this.axios
                .put('/cards/' + card.id, {
                    position: position,
                    listing_id: this.listing.id,
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    card.position = response.data.data.position;

                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
            

            // console.log(e);
        }
    },

}
</script>

<style>

</style>